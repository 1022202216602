import { defineStore } from 'pinia'
import { ref, computed } from 'vue'

export const useUserStore = defineStore('userStore', () => {
  const settings = ref({id: null, user_group_id: null, current_org: {}})
  const permits = ref({})

  const permit = (name) => {
    return permits.value[name] ? true : false
  }

  const isHijacked = computed(() => {
    return settings.value.is_hijacked
  })

  const kohanaUrl = computed(() => {
    return settings.value.kohana_url
  })

  const org = computed(() => {
    return settings.value.current_org
  })

  const userId = computed(() => {
    return settings.value.user_id
  })

  const hasFeature = (key) => {
    return settings.value?.features?.[key] || false;
  }

  const isSpecialAdmin = computed(() => {
    return settings.value.hijacker_id === 2
  })

  const userGroupId = computed(() => {
    return settings.value.user_group_id
  })

  const isAdmin = computed(() => {
    return settings.value.user_group_id === 4
  })

  const isAuthenticated = computed(() => {
    return settings.value.user_id ? true : false
  })

  const useAllNew = computed(() => {
    if (org.value.id > 657) {
      return true
    }

    return [154, 652].includes(org.value.id)
  })

  const isMyOrg = (orgId) => {
    if (!orgId) {
      return true
    }

    return org.value.id === orgId
  }

  const isThirdParty = computed(() => {
    return settings.value.is_third_party
  })

  const tokenKey = ref('freshability trace track')

  return { settings, permits, permit, isHijacked, kohanaUrl, org, hasFeature, userId, isMyOrg, isSpecialAdmin, userGroupId, isAdmin, isAuthenticated, useAllNew, tokenKey, isThirdParty }
})
